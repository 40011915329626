<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>Vllshop关于知识产权投诉公告</h1>
      <hr>
      <p>OP 致力于保护知识产权的安全，保护健康知识产权的商业环境及消费者和商家对我们的信仰。权利，包括其授权的代理，</p>
      <p>可以通过 Vllshop 投诉邮件向 <em>jingdan@wonwhale.com</em> 保护人认为侵犯您的知识产权的产品及产品描述以删除您的知识产权的方式进行投诉。</p>

      <p class="up">在Vllshop保护需要准备材料：</p>
      <p>1.身份材料，即能够核实您的身份的真实性证明资料。 如为您个人的身份证明材料。 </p>
      <p>2.权利证明， 如商标权、 专利权、著作权等权利。</p>
      <p>还需要提供：
        知识产权所有人身份材料产权所有人
        的授权委托书</p>
      <p>3. 租赁信息位置（适用链接、产品截屏）；</p>
      <p>4. 投诉真实性声明签字。</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'IntellectualProperty'
}
</script>

<style scoped>

</style>
